import React from 'react';

class SvgSvgStarterPackXs extends React.Component {
    render() {
        const { ...props } = this.props;

        return (
            <svg
                version={1.1}
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 360 173.316"
                enableBackground="new 0 0 360 173.316"
                xmlSpace="preserve"

                {...props}
            >
                <g id="Layer_1">
                    <rect y={62.153} fill="#FFFFFF" width={98.396} height={3.697} />
                    <rect
                        x={250.027}
                        y={62.268}
                        fill="#FFFFFF"
                        width={109.973}
                        height={3.698}
                    />
                    <path
                        fill="#FFFFFF"
                        d="M250.678,129.698c-14.547,23.783-40.764,39.689-70.629,39.689c-21.502,0-41.112-8.247-55.838-21.745h-5.72
		c0.095,0.098,0.188,0.196,0.284,0.293c7.958,7.958,17.225,14.205,27.545,18.57c10.684,4.521,22.033,6.812,33.73,6.812
		c11.7,0,23.046-2.291,33.733-6.812c10.318-4.365,19.586-10.612,27.545-18.57c5.482-5.485,10.154-11.593,13.961-18.236
		L250.678,129.698L250.678,129.698z"
                    />
                    <rect y={115.818} fill="#FFFFFF" width={98.396} height={3.694} />
                    <rect
                        x={66.919}
                        y={31.514}
                        fill="#FFFFFF"
                        width={8.058}
                        height={1.851}
                    />
                    <rect
                        x={80.783}
                        y={31.514}
                        fill="#FFFFFF"
                        width={8.058}
                        height={1.851}
                    />
                    <rect
                        x={94.648}
                        y={31.514}
                        fill="#FFFFFF"
                        width={8.058}
                        height={1.851}
                    />
                    <rect
                        x={107.332}
                        y={31.573}
                        fill="#FFFFFF"
                        width={8.058}
                        height={1.852}
                    />
                    <rect
                        x={122.376}
                        y={31.514}
                        fill="#FFFFFF"
                        width={8.058}
                        height={1.851}
                    />
                    <rect
                        x={136.24}
                        y={31.514}
                        fill="#FFFFFF"
                        width={8.058}
                        height={1.851}
                    />
                    <rect
                        x={150.105}
                        y={31.514}
                        fill="#FFFFFF"
                        width={8.058}
                        height={1.851}
                    />
                    <rect
                        x={163.969}
                        y={31.514}
                        fill="#FFFFFF"
                        width={8.058}
                        height={1.851}
                    />
                    <g>
                        <path
                            fill="#FFFFFF"
                            d="M104.425,44.32c-1.558,2.778-2.969,5.648-4.221,8.608c-1.781,4.209-3.214,8.521-4.3,12.921h4.068
			c1.993-7.662,5.062-14.896,9.031-21.529H104.425L104.425,44.32z"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M213.781,6.813C203.096,2.292,191.748,0,180.049,0c-11.697,0-23.046,2.292-33.731,6.813
			C136,11.178,126.733,17.426,118.775,25.384c-2.547,2.547-4.917,5.229-7.108,8.038h5.113C131.967,15.401,154.694,3.93,180.051,3.93
			c18.971,0,36.469,6.419,50.441,17.195l3.613-2.202C227.922,13.978,221.119,9.916,213.781,6.813z"
                        />
                    </g>
                    <rect
                        x={247.658}
                        y={115.818}
                        fill="#FFFFFF"
                        width={112.342}
                        height={3.694}
                    />
                    <text
                        transform="matrix(1 0 0 1 108.3579 96.3323)"
                        fill="#FFFFFF"
                        fontFamily="'Abel-Regular'"
                        fontSize={25.4176}
                    >
                        {'STARTER PACK'}
                    </text>
                    <rect
                        x={182.084}
                        y={111.235}
                        fill="none"
                        width={52.021}
                        height={13.976}
                    />
                    <rect
                        x={53.738}
                        y={31.514}
                        fill="#FFFFFF"
                        width={8.058}
                        height={1.851}
                    />
                    <rect
                        x={177.6}
                        y={112.678}
                        fill="none"
                        width={50.044}
                        height={13.445}
                    />
                    <rect
                        x={220.767}
                        y={128.39}
                        fill="#FFFFFF"
                        width={139.223}
                        height={2.545}
                    />
                </g>
                <g id="o-projekcie-strzalka">
                    <text
                        transform="matrix(1 0 0 1 165.9746 122.1936)"
                        fill="#FFFFFF"
                        fontFamily="'Abel-Regular'"
                        fontSize={11.6065}
                    >
                        {'O projekcie'}
                    </text>
                    <g>
                        <g enableBackground="new    ">
                            <path
                                fill="#FFFFFF"
                                d="M158.125,115.552l2.522,2.522l-0.517,0.517l-2.523-2.522L158.125,115.552z"
                            />
                        </g>
                        <g enableBackground="new    ">
                            <path
                                fill="#FFFFFF"
                                d="M152.521,118.078h8.127v0.731h-8.127V118.078z"
                            />
                        </g>
                        <g enableBackground="new    ">
                            <path
                                fill="#FFFFFF"
                                d="M157.608,120.819l2.523-2.521l0.517,0.516l-2.522,2.523L157.608,120.819z"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}

export default SvgSvgStarterPackXs;
