import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AboutProject from './AboutProject';
import SvgStarterPackXl from '../../svg/starterPack/SvgStarterPackXl';
import SvgStarterPackLg from '../../svg/starterPack/SvgStarterPackLg';
import SvgStarterPackMd from '../../svg/starterPack/SvgStarterPackMd';
import SvgStarterPackXs from '../../svg/starterPack/SvgStarterPackXs';
import SkewedDiv from '../SkewedDiv';
import { getSizeFromWidth } from '../../helpers/common';
import './Header.scss';


export default class extends Component {
    static propTypes = {
        width: PropTypes.number.isRequired,
        projectDescription: PropTypes.string,
        isLoading: PropTypes.bool.isRequired,
        location: PropTypes.string.isRequired,
    };

    static defaultProps = {
        projectDescription: '',
    };

    state = {
        isDescriptionOpened: false,
        isOnMainPage: true,
    };

    componentDidMount() {
        const { location } = this.props;
        this.updateListener();
        window.addEventListener('resize', this.updateListener);
        const aboutElement = document.getElementById('o-projekcie-strzalka');
        if (location !== '/'){
            aboutElement.classList.add('inVisible');
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { location } = this.props;
        const isOnMainPage = location === '/';
        const aboutElement = document.getElementById('o-projekcie-strzalka');
        if (aboutElement){
            aboutElement.addEventListener('click', this.toggleDescription);
        }
        if (prevState.isOnMainPage !== isOnMainPage) {
            this.setState({ isOnMainPage, isDescriptionOpened: false });
            if (aboutElement && isOnMainPage) {
                aboutElement.classList.remove('fadeOut');
                aboutElement.classList.add('fadeIn');
            } else if(aboutElement && !isOnMainPage){
                aboutElement.classList.remove('fadeIn');
                aboutElement.classList.add('fadeOut');
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateListener);
        document.getElementById('o-projekcie-strzalka') &&
            document
                .getElementById('o-projekcie-strzalka')
                .removeEventListener('click', this.toggleDescription);
        // document
        //     .getElementById('rejestracja')
        //     .removeEventListener('click', this.openRegistration);
    }


    updateListener = () => {
        document.getElementById('o-projekcie-strzalka') &&
            document
                .getElementById('o-projekcie-strzalka')
                .addEventListener('click', this.toggleDescription);

        // document
        //     .getElementById('rejestracja')
        //     .addEventListener('click', this.openRegistration);
    };

    openRegistration = () => {
        const win = window.open('https://goo.gl/forms/uWXvVw7jzgXtjDlO2', '_blank');
        win.focus();
    };


    toggleDescription = () => {
        const { isDescriptionOpened } = this.state;
        if (isDescriptionOpened) {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
        this.setState({isDescriptionOpened: !isDescriptionOpened});
    };

    render() {
        const { width, projectDescription, isLoading } = this.props;
        const { isDescriptionOpened } = this.state;
        let svg;
        switch (getSizeFromWidth(width)) {
            case 'xl':
                svg = <SvgStarterPackXl />;
                break;
            case 'lg':
                svg = <SvgStarterPackLg />;
                break;
            case 'md':
                svg = <SvgStarterPackMd />;
                break;
            case 'xs':
                svg = <SvgStarterPackXs />;
                break;
            case 'none':
            default:
                svg = null;
                break;
        }

        return (
            <section id="head">
                <SkewedDiv className="head-bgr">
                    <div className={`starterPackLogoContainer ${isDescriptionOpened ? 'small': ''}`}>
                        {svg}
                    </div>
                    <div className={`descriptionContainer ${isDescriptionOpened ? 'open': ''}`}>
                        <AboutProject
                            isLoading={isLoading}
                            projectDescription={projectDescription}
                            closeButton={
                                <div className="closeButtonContainer">
                                    <div onClick={this.toggleDescription} className="closeButton" >
                                        Zamknij opis
                                    </div>
                                </div>
                            }
                        />
                    </div>
                </SkewedDiv>
            </section>
        );
    }
}
