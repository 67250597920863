import React, { Component } from 'react';
import axios from 'axios';
import config from '../../SiteConfig';


export function withWPData(wpId) {
    return function (WrappedComponent) {
        return class extends Component {
            state = {
                wpData: null,
                isLoading: true,
            };

            componentDidMount() {
                axios.get(`${config.wpProtocol}://${config.wpUrl}/wp-json/wp/v2/pages/${wpId}`)
                    .then(respond => {
                        this.setState({ wpData: respond.data, isLoading: false });
                    });
            }

            render() {
                const { wpData, isLoading } = this.state;
                // ... and renders the wrapped component with the fresh data!
                // Notice that we pass through any additional props
                return <WrappedComponent wpData={wpData} isLoading={isLoading} {...this.props} />;
            }
        };
    };
}
