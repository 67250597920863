module.exports = {
    siteTitle: 'Starter Pack', // Alternative site title for SEO.
    siteTitleShort: 'Starter Pack', // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
    siteUrl: 'https://starter-pack.pl/', // Domain of your website without pathPrefix.
    siteDescription: 'STARTER PACK to autorski pakiet materiałów do ćwiczenia kompetencji, przeznaczony do wykorzystania przez nauczycieli/nauczycielki na lekcjach przedsiębiorczości, doradztwa zawodowego, czy też wychowawczych, przygotowany przez Gdańską Fundację Przedsiębiorczości, we współpracy z autorami – ekspertami, praktykami, pracującymi z daną kompetencją na co dzień.', // Website description used for RSS feeds/meta description tag.

    siteLogo: '/logo.png', // Logo used for SEO and manifest.
    googleAnalyticsID: 'UA-47311644-5', // GA tracking ID.

    themeColor: '#4d4d4d', // Used for setting manifest and progress theme colors.
    backgroundColor: '#f39', // Used for setting manifest background color.
    wpUrl: 'starter-pack.pl/admin/index.php',
    wpProtocol: 'https',
    copyright: 'Copyright © 2017.', // Copyright string for the footer of the website and RSS feed.
};
