// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-budzet-domowy-jsx": () => import("./../../../src/pages/budzet-domowy.jsx" /* webpackChunkName: "component---src-pages-budzet-domowy-jsx" */),
  "component---src-pages-budzet-obywatelski-jsx": () => import("./../../../src/pages/budzet-obywatelski.jsx" /* webpackChunkName: "component---src-pages-budzet-obywatelski-jsx" */),
  "component---src-pages-crowdfunding-rodzina-jsx": () => import("./../../../src/pages/crowdfunding-rodzina.jsx" /* webpackChunkName: "component---src-pages-crowdfunding-rodzina-jsx" */),
  "component---src-pages-crowdfunding-szkola-jsx": () => import("./../../../src/pages/crowdfunding-szkola.jsx" /* webpackChunkName: "component---src-pages-crowdfunding-szkola-jsx" */),
  "component---src-pages-edukacja-finansowa-dzieci-jsx": () => import("./../../../src/pages/edukacja-finansowa-dzieci.jsx" /* webpackChunkName: "component---src-pages-edukacja-finansowa-dzieci-jsx" */),
  "component---src-pages-efektywna-praca-zdalna-jsx": () => import("./../../../src/pages/efektywna-praca-zdalna.jsx" /* webpackChunkName: "component---src-pages-efektywna-praca-zdalna-jsx" */),
  "component---src-pages-elastycznosc-poznawcza-jsx": () => import("./../../../src/pages/elastycznosc-poznawcza.jsx" /* webpackChunkName: "component---src-pages-elastycznosc-poznawcza-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-inteligencja-emocjonalna-jsx": () => import("./../../../src/pages/inteligencja-emocjonalna.jsx" /* webpackChunkName: "component---src-pages-inteligencja-emocjonalna-jsx" */),
  "component---src-pages-kompetencje-ekonomiczne-index-jsx": () => import("./../../../src/pages/kompetencje-ekonomiczne/index.jsx" /* webpackChunkName: "component---src-pages-kompetencje-ekonomiczne-index-jsx" */),
  "component---src-pages-kompetencje-przyszlosci-index-jsx": () => import("./../../../src/pages/kompetencje-przyszlosci/index.jsx" /* webpackChunkName: "component---src-pages-kompetencje-przyszlosci-index-jsx" */),
  "component---src-pages-kompleksowe-rozwiazywanie-problemow-jsx": () => import("./../../../src/pages/kompleksowe-rozwiazywanie-problemow.jsx" /* webpackChunkName: "component---src-pages-kompleksowe-rozwiazywanie-problemow-jsx" */),
  "component---src-pages-komunikacja-oraz-wspolpraca-jsx": () => import("./../../../src/pages/komunikacja-oraz-wspolpraca.jsx" /* webpackChunkName: "component---src-pages-komunikacja-oraz-wspolpraca-jsx" */),
  "component---src-pages-kreatywnosc-jsx": () => import("./../../../src/pages/kreatywnosc.jsx" /* webpackChunkName: "component---src-pages-kreatywnosc-jsx" */),
  "component---src-pages-kup-starter-pack-jsx": () => import("./../../../src/pages/kup-starter-pack.jsx" /* webpackChunkName: "component---src-pages-kup-starter-pack-jsx" */),
  "component---src-pages-myslenie-krytyczne-jsx": () => import("./../../../src/pages/myslenie-krytyczne.jsx" /* webpackChunkName: "component---src-pages-myslenie-krytyczne-jsx" */),
  "component---src-pages-negocjacje-jsx": () => import("./../../../src/pages/negocjacje.jsx" /* webpackChunkName: "component---src-pages-negocjacje-jsx" */),
  "component---src-pages-ocena-i-podejmowanie-decyzji-jsx": () => import("./../../../src/pages/ocena-i-podejmowanie-decyzji.jsx" /* webpackChunkName: "component---src-pages-ocena-i-podejmowanie-decyzji-jsx" */),
  "component---src-pages-praca-przyszlosci-index-jsx": () => import("./../../../src/pages/praca-przyszlosci/index.jsx" /* webpackChunkName: "component---src-pages-praca-przyszlosci-index-jsx" */),
  "component---src-pages-priorytety-osobiste-jsx": () => import("./../../../src/pages/priorytety-osobiste.jsx" /* webpackChunkName: "component---src-pages-priorytety-osobiste-jsx" */),
  "component---src-pages-rodzaje-dzialalnosci-jsx": () => import("./../../../src/pages/rodzaje-dzialalnosci.jsx" /* webpackChunkName: "component---src-pages-rodzaje-dzialalnosci-jsx" */),
  "component---src-pages-rozmowa-rekrutacyjna-jsx": () => import("./../../../src/pages/rozmowa-rekrutacyjna.jsx" /* webpackChunkName: "component---src-pages-rozmowa-rekrutacyjna-jsx" */),
  "component---src-pages-silne-strony-osobowosci-jsx": () => import("./../../../src/pages/silne-strony-osobowosci.jsx" /* webpackChunkName: "component---src-pages-silne-strony-osobowosci-jsx" */),
  "component---src-pages-spoleczny-aspekt-biznesu-jsx": () => import("./../../../src/pages/spoleczny-aspekt-biznesu.jsx" /* webpackChunkName: "component---src-pages-spoleczny-aspekt-biznesu-jsx" */),
  "component---src-pages-strategia-budowania-kariery-jsx": () => import("./../../../src/pages/strategia-budowania-kariery.jsx" /* webpackChunkName: "component---src-pages-strategia-budowania-kariery-jsx" */),
  "component---src-pages-wartosc-pieniadza-jsx": () => import("./../../../src/pages/wartosc-pieniadza.jsx" /* webpackChunkName: "component---src-pages-wartosc-pieniadza-jsx" */),
  "component---src-pages-wizerunek-w-sieci-jsx": () => import("./../../../src/pages/wizerunek-w-sieci.jsx" /* webpackChunkName: "component---src-pages-wizerunek-w-sieci-jsx" */),
  "component---src-pages-zarzadzanie-zespolem-jsx": () => import("./../../../src/pages/zarzadzanie-zespolem.jsx" /* webpackChunkName: "component---src-pages-zarzadzanie-zespolem-jsx" */),
  "component---src-pages-zerowaste-dom-jsx": () => import("./../../../src/pages/zerowaste-dom.jsx" /* webpackChunkName: "component---src-pages-zerowaste-dom-jsx" */),
  "component---src-pages-zerowaste-szkola-jsx": () => import("./../../../src/pages/zerowaste-szkola.jsx" /* webpackChunkName: "component---src-pages-zerowaste-szkola-jsx" */),
  "component---src-pages-zorientowanie-na-produkt-jsx": () => import("./../../../src/pages/zorientowanie-na-produkt.jsx" /* webpackChunkName: "component---src-pages-zorientowanie-na-produkt-jsx" */)
}

