import React from 'react';
import posed, { PoseGroup } from 'react-pose';
import Layout from './src/layout';
import constants from './src/constants/javascriptConstants';

export const shouldUpdateScroll = ({prevRouterProps, getSavedScrollPosition}) => {
    return false;
};

export const wrapRootElement = ({ element }) => (
    <Layout>
        {element}
    </Layout>
);

export const onRouteUpdate = ({ location }) => {
    if (document.body.classList.contains('pinkTheme')) {
        document.body.classList.remove('pinkTheme');
    }
    if (document.body.classList.contains('blueTheme')){
        document.body.classList.remove('blueTheme');
    }
    if (document.body.classList.contains('greenTheme')){
        document.body.classList.remove('greenTheme');
    }
    if (document.body.classList.contains('grayTheme')){
        document.body.classList.remove('grayTheme');
    }
    if (constants.economicLinks.some(linkElement => location.pathname.includes(linkElement))) {
        document.body.classList.add('blueTheme');
    }
    else if (constants.futureLinks.some(linkElement => location.pathname.includes(linkElement))) {
        document.body.classList.add('pinkTheme');
    }
    else if (constants.workLinks.some(linkElement => location.pathname.includes(linkElement))) {
        document.body.classList.add('greenTheme');
    }
    else {
        document.body.classList.add('grayTheme');
    }
};

const Transition = posed.div();
export const replaceComponentRenderer = ({ props, ...other }) => {
    const { component } = props.pageResources;
    return (
        <PoseGroup>
            <Transition key={props.location.key}>
                {React.createElement(component, props)}
            </Transition>
        </PoseGroup>
    );
};
