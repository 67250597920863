import React from 'react';

class SvgSvgContactXl extends React.Component {
    render() {
        const { ...props } = this.props;

        return (
            <svg viewBox="0 0 1920 517.221"  {...props}>
                <path
                    className="st2"
                    d="M.001 503.721h861.298L427.745 70.167.001 497.909z"
                    id="mail"
                />
                <path
                    className="st2"
                    d="M689.541 344.717l274.551-274.55 265.674 265.674-164.266 167.88h-213z"
                    id="phone"
                />
                <path
                    className="st2"
                    d="M1920 487.352L1502.814 70.167 1069.26 503.721H1920z"
                    id="map"
                />
                <g id="kontakt">
                    <path className="st0" d="M1920 48.677v429.796l-214.897-214.898z" />
                    <path
                        transform="rotate(45.001 1232.599 160.839)"
                        className="st4"
                        d="M1119.186 47.443h226.828v226.829h-226.828z"
                    />
                    <path
                        transform="rotate(45.001 695.527 192.41)"
                        className="st4"
                        d="M605.734 102.628H785.32v179.588H605.734z"
                    />
                    <path
                        transform="rotate(45.001 568.852 115.913)"
                        className="st0"
                        d="M514.758 61.827h108.188v108.189H514.758z"
                    />
                    <path
                        className="st2"
                        d="M1223.441 272.793l16.316-16.167 1.999 2.04-16.316 16.166zM1255.689 261.995l-21.545-21.545 1.938-1.938 21.545 21.546-1.938 1.937zm10.518-10.517l-18.887-2.659-2.659-18.886 2.403-2.404 2.494 19.051 19.052 2.495-2.403 2.403zM1280.157 231.668a7.512 7.512 0 0 1 .001 5.633 7.31 7.31 0 0 1-1.646 2.473l-1.532 1.532c-.731.731-1.558 1.282-2.479 1.652s-1.863.556-2.825.556c-.961 0-1.9-.182-2.816-.549a7.292 7.292 0 0 1-2.473-1.645l-11.568-11.569a7.306 7.306 0 0 1-1.646-2.472 7.546 7.546 0 0 1-.548-2.816c0-.963.186-1.903.556-2.825a7.377 7.377 0 0 1 1.651-2.479l1.533-1.532a7.32 7.32 0 0 1 2.473-1.646c.916-.364 1.854-.548 2.816-.548s1.9.183 2.816.548a7.305 7.305 0 0 1 2.473 1.646l11.568 11.569a7.267 7.267 0 0 1 1.646 2.472zm-15.152-12.102a4.748 4.748 0 0 0-1.615-1.074 4.936 4.936 0 0 0-1.825-.354c-.621.001-1.23.118-1.826.354s-1.134.593-1.615 1.074l-1.352 1.352c-.48.48-.84 1.02-1.074 1.615a5.075 5.075 0 0 0-.361 1.834 4.868 4.868 0 0 0 .346 1.832 4.729 4.729 0 0 0 1.074 1.615l11.569 11.569a4.75 4.75 0 0 0 1.616 1.075 4.973 4.973 0 0 0 3.68 0 4.74 4.74 0 0 0 1.616-1.075l1.353-1.353c.48-.48.838-1.019 1.074-1.615a4.88 4.88 0 0 0 .346-1.832c-.006-.626-.126-1.237-.361-1.833s-.594-1.135-1.074-1.616l-11.571-11.568zM1299.23 218.454l-24.67-6.702-1.111-.751 16.617 16.617-1.938 1.938-21.546-21.546 1.007-1.006 24.687 6.716 1.111.722-16.617-16.617 1.938-1.939 21.546 21.546-1.024 1.022zM1290.713 187.577l19.697 19.697-1.938 1.938-19.697-19.697-5.139 5.139-1.849-1.849 12.216-12.215 1.849 1.848-5.139 5.139zM1320.777 186.872l-6.822 6.82 3.606 6.431-2.014 2.014-15.235-27.856 1.022-1.021 27.886 15.205-1.998 1.998-6.445-3.591zm-8.144 4.448l5.785-5.785-12.771-7.092-.856-.736.736.856 7.106 12.757zM1331.985 185.699l-21.546-21.546 1.938-1.938 21.546 21.545-1.938 1.939zm10.518-10.517l-18.887-2.659-2.659-18.887 2.404-2.402 2.494 19.051 19.051 2.494-2.403 2.403zM1333.518 144.773l19.697 19.697-1.938 1.938-19.697-19.697-5.139 5.139-1.848-1.848 12.215-12.216 1.848 1.849-5.138 5.138z"
                    />
                    <path
                        transform="rotate(45.001 1392.59 80.703)"
                        className="st0"
                        d="M1335.835 23.972h113.495v113.495h-113.495z"
                    />
                    <path
                        className="st0"
                        d="M.001 478.819l-.027-440.93 220.478 220.479z"
                    />
                    <path
                        className="st3"
                        d="M.001 517.221h893.89L427.745 51.075.001 478.819v38.402zm828.706-27H26.782L427.745 89.259l400.962 400.962zM1920 468.26L1502.814 51.075l-466.146 466.146H1920V468.26zM1502.814 89.259l400.963 400.962h-801.926l400.963-400.962z"
                    />
                    <g>
                        <path
                            className="st3"
                            d="M964.092 89.259l269.361 269.361 19.092-19.091L964.092 51.075l-274.551 274.55 17.494 20.691zM1365.054 490.221H524.945l-27 27h932.292l-27-27z"
                        />
                    </g>
                </g>
            </svg>
        );
    }
}

export default SvgSvgContactXl;
