import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SvgLoader from '../../svg/icons/SvgLoader';
import './aboutProject.scss';


export default class extends Component {
    static propTypes = {
        closeButton: PropTypes.element.isRequired,
        projectDescription: PropTypes.string.isRequired,
        isLoading: PropTypes.bool.isRequired,
    };
    // static defaultProps = {};
    // state = {};

    render() {
        const {
            closeButton,
            projectDescription,
            isLoading
        } = this.props;

        if (isLoading) {
            return (
                <div style={{display: 'flex', justifyContent: 'center', margin: '25% 25%'}}>
                    <div className={'svg-container'}>
                        <SvgLoader className={'svg-position'} height={100}/>
                        {closeButton}
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: projectDescription }} />
                    {closeButton}
                </div>
            );
        }
    }
}


// OLD CONTENT


{/*<h2>Czym jest STARTER PACK?</h2>*/}
{/*<p>*/}
{/*STARTER PACK to autorski pakiet materiałów do ćwiczenia kompetencji, przeznaczony do*/}
    {/*wykorzystania przez nauczycieli/nauczycielki na lekcjach przedsiębiorczości, doradztwa*/}
{/*zawodowego, czy też wychowawczych, przygotowany przez Gdańską Fundację Przedsiębiorczości,*/}
    {/*we współpracy z autorami – ekspertami, praktykami, pracującymi z daną kompetencją na co*/}
{/*dzień.*/}
{/*</p>*/}
{/*<p>*/}
{/*Na „STARTER PACK” składają się:*/}
    {/*</p>*/}
{/*<ol>*/}
{/*<li>*/}
{/*<div><strong>Pudełko „STARTER PACK”</strong>, zawierające: </div>*/}
{/*<ul>*/}
{/*<li>przewodnik dla nauczyciela,</li>*/}
{/*<li>zestawy fiszek kompetencji,</li>*/}
{/*<li>plansza z Modelem Biznesowym Canvas,</li>*/}
{/*<li>przykładowe scenariusze lekcji.</li>*/}
{/*</ul>*/}
{/*</li>*/}
{/*<li>*/}
{/*<strong>Materiały on-line</strong> zamieszczone na stronie internetowej (<a href="//www.starter-pack.pl">www.starter-pack.pl</a>), powiązane z treścią fiszek kompetencji (wkrótce).*/}
{/*</li>*/}
{/*</ol>*/}

{/*<p>Proponowane materiały do wykorzystania w pracy z uczniami/młodzieżą na lekcjach i poza*/}
    {/*lekcjami:</p>*/}
{/*<ul>*/}
{/*<li>ukierunkowane są na rozwijanie kompetencji przyszłości,</li>*/}
{/*<li>przybliżają uczniów do przedsiębiorczości praktycznej poprzez odpowiadanie na realne wyzwania projektowe,</li>*/}
{/*<li>wspierają realizację zajęć z doradztwa zawodowego: analiza osobista mocnychstron, introspekcja, realne zastosowanie ćwiczonych kompetencji.</li>*/}
{/*</ul>*/}

{/*<p>*/}
    {/*Zarówno proponowana forma, jak i treści, jako nowy styl przekazywania informacji*/}
    {/*o przedsiębiorczości – w oparciu o kompetencje – są innowacyjne i nie występują w takiej formie na rynku.*/}

{/*</p>*/}
{/*<p>*/}
{/*Na naszej stronie możecie zapoznać się z autorami fiszek kompetencji i przygotowanymi przez nich materiałami uzupełniającymi treść materiałów drukowanych – już wkrótce!*/}
{/*</p>*/}
{/*<p>*/}
    {/*Projekt STARTER PACK’a jest w fazie testowej. Szkoły zainteresowane materiałami prosimy o kontakt.*/}
{/*</p>*/}
{/*<p>*/}
{/*<em>Materiały powstały jako element projektu na przygotowanie pakietu materiałów do nauczania*/}
    {/*przedsiębiorczości „STARTER PACK”, który jest realizowany przez Gdańską Fundację*/}
{/*Przedsiębiorczości [GFP] – operatora Gdańskiego Inkubatora Przedsiębiorczości STARTER.</em>*/}
{/*</p>*/}
{/*<p>*/}
    {/*<em> STARTER PACK jest współfinansowany ze środków Miasta Gdańska. </em>*/}
{/*</p>*/}
