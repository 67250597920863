import React from 'react';

class SvgSvgStarterPackMd extends React.Component {
    render() {
        const { ...props } = this.props;

        return (
            <svg
                version={1.1}
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 768 347.334"
                enableBackground="new 0 0 768 347.334"
                xmlSpace="preserve"
                {...props}
            >
                <g id="Layer_1">
                    <rect y={124.553} fill="#FFFFFF" width={220.322} height={7.41} />
                    <rect
                        x={530.914}
                        y={124.786}
                        fill="#FFFFFF"
                        width={237.086}
                        height={7.407}
                    />
                    <path
                        fill="#FFFFFF"
                        d="M525.507,259.922c-29.152,47.662-81.692,79.539-141.544,79.539c-43.091,0-82.393-16.529-111.906-43.576
		h-11.463c0.19,0.193,0.377,0.391,0.57,0.584c15.948,15.947,34.52,28.469,55.199,37.217c21.414,9.059,44.157,13.648,67.6,13.648
		c23.443,0,46.189-4.592,67.604-13.648c20.68-8.748,39.252-21.27,55.199-37.217c10.992-10.994,20.355-23.23,27.979-36.547H525.507
		L525.507,259.922z"
                    />
                    <rect y={232.1} fill="#FFFFFF" width={220.322} height={7.406} />
                    <rect
                        x={157.243}
                        y={63.152}
                        fill="#FFFFFF"
                        width={16.147}
                        height={3.705}
                    />
                    <rect
                        x={185.028}
                        y={63.152}
                        fill="#FFFFFF"
                        width={16.148}
                        height={3.705}
                    />
                    <rect
                        x={212.812}
                        y={63.152}
                        fill="#FFFFFF"
                        width={16.149}
                        height={3.705}
                    />
                    <rect
                        x={238.234}
                        y={63.268}
                        fill="#FFFFFF"
                        width={16.147}
                        height={3.707}
                    />
                    <rect
                        x={268.382}
                        y={63.152}
                        fill="#FFFFFF"
                        width={16.148}
                        height={3.705}
                    />
                    <rect
                        x={296.167}
                        y={63.152}
                        fill="#FFFFFF"
                        width={16.147}
                        height={3.705}
                    />
                    <rect
                        x={323.951}
                        y={63.152}
                        fill="#FFFFFF"
                        width={16.148}
                        height={3.705}
                    />
                    <rect
                        x={351.737}
                        y={63.152}
                        fill="#FFFFFF"
                        width={16.147}
                        height={3.705}
                    />
                    <g>
                        <path
                            fill="#FFFFFF"
                            d="M232.406,88.813c-3.12,5.572-5.95,11.324-8.457,17.254c-3.57,8.438-6.443,17.084-8.617,25.896h8.151
			c3.994-15.357,10.144-29.854,18.098-43.148L232.406,88.813L232.406,88.813z"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M451.565,13.648C430.151,4.591,407.407,0,383.962,0c-23.441,0-46.186,4.592-67.6,13.648
			c-20.68,8.748-39.251,21.27-55.2,37.219c-5.104,5.104-9.854,10.48-14.246,16.105h10.248
			c30.437-36.115,75.98-59.101,126.798-59.101c38.018,0,73.086,12.862,101.084,34.463l7.244-4.416
			C479.903,28.007,466.27,19.87,451.565,13.648z"
                        />
                    </g>
                    <rect
                        x={525.785}
                        y={232.1}
                        fill="#FFFFFF"
                        width={242.215}
                        height={7.406}
                    />
                    <text
                        transform="matrix(1 0 0 1 240.2842 193.0518)"
                        fill="#FFFFFF"
                        fontFamily="'Abel-Regular'"
                        fontSize={50.9386}
                    >
                        {'STARTER PACK'}
                    </text>
                    <rect
                        x={388.038}
                        y={222.918}
                        fill="none"
                        width={104.254}
                        height={28.01}
                    />
                    <rect
                        x={130.826}
                        y={63.152}
                        fill="#FFFFFF"
                        width={16.148}
                        height={3.705}
                    />
                    <rect
                        x={477.966}
                        y={255.649}
                        fill="#FFFFFF"
                        width={290.034}
                        height={5.302}
                    />
                </g>
                <g id="o-projekcie-strzalka">
                    <text
                        transform="matrix(1 0 0 1 363.8203 242.7393)"
                        fill="#FFFFFF"
                        fontFamily="'Abel-Regular'"
                        fontSize={24.1791}
                    >
                        {'O projekcie'}
                    </text>
                    <g>
                        <g enableBackground="new    ">
                            <path
                                fill="#FFFFFF"
                                d="M347.468,228.903l5.255,5.256l-1.076,1.077l-5.255-5.256L347.468,228.903z"
                            />
                        </g>
                        <g enableBackground="new    ">
                            <path
                                fill="#FFFFFF"
                                d="M335.792,234.168h16.93v1.522h-16.93V234.168z"
                            />
                        </g>
                        <g enableBackground="new    ">
                            <path
                                fill="#FFFFFF"
                                d="M346.391,239.876l5.255-5.254l1.076,1.076l-5.255,5.255L346.391,239.876z"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}

export default SvgSvgStarterPackMd;
