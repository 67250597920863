import React, { Component } from 'react';


export function withWidth(WrappedComponent) {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                width: this.getWidth(),
            };
        }

        componentDidMount() {
            window.addEventListener('resize', this.updateWidth);
        }

        componentWillUnmount() {
            window.removeEventListener('resize', this.updateWidth);
        }

        getWidth = () => (typeof document !== 'undefined'
            ? window.innerWidth : 0);

        updateWidth = () => {
            this.setState({width: this.getWidth()});
        };

        render() {
            // ... and renders the wrapped component with the fresh data!
            // Notice that we pass through any additional props
            return <WrappedComponent width={this.state.width} {...this.props} />;
        }
    };
}
