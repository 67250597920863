import React, { Component } from 'react';
import { getSizeFromWidth } from '../../helpers/common';
import PropTypes from 'prop-types';
import SvgContactXl from '../../svg/contact/SvgContactXl';
import SvgContactMd from '../../svg/contact/SvgContactMd';
import SvgContactXs from '../../svg/contact/SvgContactXs';
// import SvgContactSm from '../../svg/SvgContactSm';
// import SvgContactXs from '../../svg/contact/SvgContactXs';
import './Contact.scss';
import SkewedDiv from '../SkewedDiv';
import SvgSvgIconMap from '../../svg/icons/SvgIconMap';
import SvgSvgIconPhone from '../../svg/icons/SvgIconPhone';
import SvgSvgIconEmail from '../../svg/icons/SvgIconEmail';


export default class extends Component {
    static propTypes = {
        width: PropTypes.number.isRequired,
    };

    render() {
        const { width } = this.props;
        let svg;
        switch (getSizeFromWidth(width)) {
            case 'xl':
                svg = <SvgContactXl className="geometry"/>;
                break;
            case 'lg':
                svg = <SvgContactXl className="geometry" />;
                break;
            case 'md':
                svg = <SvgContactMd className="geometry" />;
                // svg = <SvgContactMd />;
                break;
            // case 'sm':
            //     svg = <SvgContactSm />;
            //     break;
            case 'xs':
                // svg = <SvgContactXs />;
                svg = <SvgContactXs className="geometry" />; // different SVG file
                break;
            case 'none':
            default:
                svg = null;
                break;
        }
        return (
            <section id="contact">
                <div>
                    {svg}
                    <div className="icoTextPair address">
                        <SvgSvgIconMap className="svg-icon-contact" />
                        <div>
                            <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href="https://www.google.pl/maps/place/Inkubator+STARTER/data=!4m12!1m6!3m5!1s0x46fd752c0ad3fbbd:0x6c80a51bd39fe2ed!2sInkubator+STARTER!8m2!3d54.4046698!4d18.5760046!3m4!1s0x46fd752c0ad3fbbd:0x6c80a51bd39fe2ed!8m2!3d54.4046698!4d18.5760046"
                            >
                            Gdańsk ul. Lęborska 3b
                            </a>
                        </div>
                    </div>
                    <div className="icoTextPair mail">
                        <SvgSvgIconEmail className="svg-icon-contact" />
                        <div>
                            <a href="mailto:edukacja@inkubatorstarter.pl">
                                edukacja@inkubatorstarter.pl
                            </a>
                        </div>
                    </div>
                    <div className="icoTextPair phone">
                        <SvgSvgIconPhone className="svg-icon-contact" />
                        <div>
                            <a href="tel:+48587316585">
                                +48 58 731 65 85
                            </a>
                        </div>
                    </div>
                    <SkewedDiv className="contactBackground" />
                </div>
            </section>
        );
    }
}
