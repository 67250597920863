const constants = {
    mdMinBreakpoint: 700,
    lgMinBreakpoint: 1100,
    xlMinBreakpoint: 1600,

    skewDegrees: 6,
    imageHeight: 300,

    competencesTypes: {
        future: 'future',
        economic: 'economic',
        futureWork: 'futureWork',
    },

    isCovid: false,

    economicLinks: [
        'wartosc-pieniadza/',
        'budzet-obywatelski/',
        'crowdfunding-szkola/',
        'zerowaste-szkola/',
        'budzet-domowy/',
        'edukacja-finansowa-dzieci/',
        'crowdfunding-rodzina/',
        'zerowaste-dom/',
        'kompetencje-ekonomiczne/'
    ],

    futureLinks: [
        'elastycznosc-poznawcza/',
        'inteligencja-emocjonalna/',
        'kompleksowe-rozwiazywanie-problemow/',
        'kreatywnosc/',
        'myslenie-krytyczne/',
        'negocjacje/',
        'ocena-i-podejmowanie-decyzji/',
        'zarzadzanie-zespolem/',
        'zorientowanie-na-produkt/',
        'kompetencje-przyszlosci/',
        'komunikacja-oraz-wspolpraca/'
    ],

    workLinks: [
        'praca-przyszlosci/',
        'efektywna-praca-zdalna/',
        'rozmowa-rekrutacyjna/',
        'silne-strony-osobowosci/',
        'priorytety-osobiste/',
        'wizerunek-w-sieci/',
        'rodzaje-dzialalnosci/',
        'spoleczny-aspekt-biznesu/',
        'strategia-budowania-kariery/'
    ]
};
export default constants;
