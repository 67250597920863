import React, { Component } from 'react';
import './Footer.scss';
import SvgStarterLogo from '../../svg/icons/Starter';
import SvgFacebookIcon from '../../svg/icons/Facebook';
import SvgTwitterIcon from '../../svg/icons/Twitter';
import SvgStartusIcon from '../../svg/icons/ZnakLudziWezy';
import SvgGdanskLogo from '../../svg/icons/GdanskLogo';
import SvgCommonWombat from '../../svg/icons/SvgCommonWombat';

class Footer extends Component {
    render() {
        return (
            <section id="footer">
                <footer>
                    <div id="footer-top" className="footer-top">
                        <div id="gdansk-logo-container" className="gdansk-logo-container">
                            <SvgGdanskLogo className="gdansk-funded-logo"/>
                            <div className="gdansk-funded-text">
                                <span>Sfinansowano<br/>ze środków<br/>miasta Gdańska<br/></span>
                            </div>
                        </div>
                        <div id="starter-logo-container" className="starter-logo-container">
                            <a target="_blank" href="https://inkubatorstarter.pl/">
                                <SvgStarterLogo/>
                            </a>
                            <span className="starter-info-text">NIP: 583-290-74-40</span>
                            <span className="starter-info-text">REGON: 220049622</span>
                        </div>
                        <div id="social-icons-container" className="social-icons-container">
                            <a href="https://www.facebook.com/StarterEdukacja" aria-label="facebook" rel="nofollow">
                                <div className="social-icon-wrapper">
                                    <SvgFacebookIcon height={100}/>
                                </div>
                            </a>
                            {/*<a href="https://twitter.com/InkubatorStart" aria-label="twitter" rel="nofollow">*/}
                            {/*    <div className="social-icon-wrapper">*/}
                            {/*        <SvgTwitterIcon height={100}/>*/}
                            {/*    </div>*/}
                            {/*</a>*/}
                            {/*<a href="https://www.startus.cc/company/3401" aria-label="strona główna startera" className="startus-icon-link" rel="nofollow">*/}
                            {/*    <div className="social-icon-wrapper">*/}
                            {/*        <SvgStartusIcon height={100}/>*/}
                            {/*    </div>*/}
                            {/*</a>*/}
                        </div>
                    </div>
                    <div id="footer-bottom" className="footer-bottom">
                        <div id="cw-link-container" className="cw-link-container">
                            <a href="https://commonwombat.pl" rel="nofollow">
                                Wykonanie:
                                <SvgCommonWombat height={30}/>
                                Common Wombat
                            </a>
                        </div>
                    </div>
                </footer>
            </section>
        );
    }
}

export default Footer;
