import React from 'react';
import PropTypes from 'prop-types';

/**
 * Żeby poprawnie działało ustawianie koloru należy ustawić atrybut `fill` tak by w odpowiednich miejscacach przyjmował wartość zmiennej `color`. to jest: `fill={color}`.
 * Jeżeli któryś z atrybutów 'fill' ma pozostać z zafiksowanym kolorem to należy wartość hexową podmienić na zmienną css, czyli fill="#FFF" powinien stać się fill="var(--white)".
 *
 * Na koniec należy zmienić nazwę pliku tak by zaczynała się od 'Svg'. np. SvgPiorunek.js
 *
 *  Jeśli ikona ma mieć rozmiar w przyjmowanych props'ach to:
 *  # należy w propTypes i defaultProps odkomentować linijkę z parametrem size i w defaultProps ustawić jakąś wartość.
 *  # znaleść tag 'svg' i fragment 'width="1em" height="1em"' podmienić na 'width={`${size}px`} height={`${size}px`}'
 *  # odkomentować 'size'  w  'const {//size, color, ...props} = this.props;'
 */

class SvgLoader extends React.Component {
    static propTypes = {
        height: PropTypes.number
    };

    static defaultProps = {
        height: 100
    };

    render() {
        const { height, ...props } = this.props;

        return (
            <svg
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
                className="lds-wedges"
                style={{ background: '0% 0%' }}
                width={height}
                height={height}
                {...props}
            >
                <g transform="matrix(.7 0 0 .7 15 15)">
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        calcMode="linear"
                        values="0 50 50;360 50 50"
                        keyTimes="0;1"
                        dur="0.75s"
                        begin="0s"
                        repeatCount="indefinite"
                    />
                    <path d="M50 50V0a50 50 0 0 1 50 50z" fillOpacity={0.8} fill="var(--primary-color)" />
                </g>
                <g transform="matrix(.7 0 0 .7 15 15)">
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        calcMode="linear"
                        values="0 50 50;360 50 50"
                        keyTimes="0;1"
                        dur="1s"
                        begin="0s"
                        repeatCount="indefinite"
                    />
                    <path
                        d="M50 50h50a50 50 0 0 1-50 50z"
                        fillOpacity={0.8}
                        fill="var(--secondary-color)"
                    />
                </g>
                <g transform="matrix(.7 0 0 .7 15 15)">
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        calcMode="linear"
                        values="0 50 50;360 50 50"
                        keyTimes="0;1"
                        dur="1.5s"
                        begin="0s"
                        repeatCount="indefinite"
                    />
                    <path
                        d="M50 50v50A50 50 0 0 1 0 50z"
                        fillOpacity={0.8}
                        fill="#4d4d4d"
                    />
                </g>
                <g transform="matrix(.7 0 0 .7 15 15)">
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        calcMode="linear"
                        values="0 50 50;360 50 50"
                        keyTimes="0;1"
                        dur="3s"
                        begin="0s"
                        repeatCount="indefinite"
                    />
                    <path d="M50 50H0A50 50 0 0 1 50 0z" fillOpacity={0.8} fill="#666" />
                </g>
            </svg>
        );
    }
}

export default SvgLoader;
