import React from 'react';
import PropTypes from 'prop-types';

/**
 * Żeby poprawnie działało ustawianie koloru należy ustawić atrybut `fill` tak by w odpowiednich miejscacach przyjmował wartość zmiennej `color`. to jest: `fill={color}`.
 * Jeżeli któryś z atrybutów 'fill' ma pozostać z zafiksowanym kolorem to należy wartość hexową podmienić na zmienną css, czyli fill="#FFF" powinien stać się fill="var(--white)".
 *
 * Na koniec należy zmienić nazwę pliku tak by zaczynała się od 'Svg'. np. SvgPiorunek.js
 *
 *  Jeśli ikona ma mieć rozmiar w przyjmowanych props'ach to:
 *  # należy w propTypes i defaultProps odkomentować linijkę z parametrem size i w defaultProps ustawić jakąś wartość.
 *  # znaleść tag 'svg' i fragment 'width="1em" height="1em"' podmienić na 'width={`${size}px`} height={`${size}px`}'
 *  # odkomentować 'size'  w  'const {//size, color, ...props} = this.props;'
 */

class SvgCommonWombat extends React.Component {
    static propTypes = {
        height: PropTypes.number
    };

    static defaultProps = {
        height: 100
    };

    render() {
        const { height, ...props } = this.props;

        return (
            <svg
                className="cw-logo"
                version={1.2}
                viewBox="0 0 595.28 595.28"
                width={height}
                height={height}
                {...props}
            >
                <path
                    className="wombat-logo-eyes-and-nose-color"
                    d="M486.475 116.088l-381.939-.418-86.977 279.702 275.307 197.431 6.093-.509 278.762-202.455z"
                />
                <path
                    className="wombat-logo-body-color"
                    d="M112.56 463.5l45.942-45.525.152.151.012-.012c6.843 5.767 15.669 9.255 25.319 9.255 21.721 0 39.329-17.608 39.329-39.329s-17.608-39.329-39.329-39.329-39.329 17.608-39.329 39.329c0 9.252 3.214 17.742 8.561 24.459l.507.504-46.709 46.52 5.545 3.977zM478.978 463.622l-45.942-45.525-.152.151-.012-.012c-6.843 5.767-15.669 9.255-25.319 9.255-21.721 0-39.329-17.608-39.329-39.329s17.608-39.329 39.329-39.329 39.329 17.608 39.329 39.329c0 9.252-3.214 17.742-8.561 24.459l-.507.504 46.709 46.52-5.545 3.977z"
                />
                <path
                    className="wombat-logo-body-color"
                    d="M487.419 115.181l24.105-70.137-61.661-43.76-.941.684-.002 112.248-306.736-.468.002-112.076-.948-.673-60.525 44.045 22.857 69.716-87.412 281.1 85.104 61.03 43.772-43.255c-4.044-6.772-6.961-16.412-6.961-24.874 0-25 20.267-45.267 45.267-45.267s45.267 20.266 45.267 45.267c0 25-20.267 45.267-45.267 45.267-8.772 0-18.318-3.576-25.246-7.902l-42.021 41.385 176.77 126.767v-66.767l-62.483-44.076 25.773-74.991H334.9l24.882 74.691-60.815 44.254v66.376l175.382-127.374-41.052-40.43c-6.928 4.327-16.474 7.902-25.246 7.902-25 0-45.267-20.267-45.267-45.267 0-25 20.267-45.267 45.267-45.267 25 0 45.267 20.267 45.267 45.267 0 8.461-2.917 18.102-6.961 24.874l42.725 42.221 90.04-65.393-91.703-275.117z"
                />
            </svg>
        );
    }
}

export default SvgCommonWombat;
