import React from 'react';

class SvgSvgStarterPackLg extends React.Component {
    render() {
        const { ...props } = this.props;

        return (
            <svg
                version={1.1}
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 1366 508.4"
                enableBackground="new 0 0 1366 508.4"
                xmlSpace="preserve"
                {...props}
            >
                <g id="starter-pack">
                    <rect
                        x={0.75}
                        y={182.313}
                        fill="#FFFFFF"
                        width={442.005}
                        height={10.847}
                    />
                    <rect
                        x={925.044}
                        y={182.653}
                        fill="#FFFFFF"
                        width={440.956}
                        height={10.844}
                    />
                    <path
                        fill="#FFFFFF"
                        d="M889.459,380.452c-42.672,69.766-119.578,116.424-207.18,116.424c-63.074,0-120.602-24.192-163.8-63.783
		h-16.778c0.278,0.281,0.553,0.572,0.834,0.854c23.344,23.343,50.526,41.67,80.798,54.476
		c31.343,13.256,64.634,19.978,98.947,19.978c34.313,0,67.605-6.722,98.947-19.978c30.271-12.806,57.453-31.133,80.801-54.476
		c16.09-16.092,29.795-34.004,40.953-53.493H889.46L889.459,380.452L889.459,380.452z"
                    />
                    <rect
                        x={0.75}
                        y={339.729}
                        fill="#FFFFFF"
                        width={442.005}
                        height={10.843}
                    />
                    <rect
                        x={350.424}
                        y={92.438}
                        fill="#FFFFFF"
                        width={23.637}
                        height={5.426}
                    />
                    <rect
                        x={391.093}
                        y={92.438}
                        fill="#FFFFFF"
                        width={23.639}
                        height={5.426}
                    />
                    <rect
                        x={431.761}
                        y={92.438}
                        fill="#FFFFFF"
                        width={23.64}
                        height={5.426}
                    />
                    <rect
                        x={468.971}
                        y={92.607}
                        fill="#FFFFFF"
                        width={23.638}
                        height={5.424}
                    />
                    <rect
                        x={513.1}
                        y={92.438}
                        fill="#FFFFFF"
                        width={23.639}
                        height={5.426}
                    />
                    <rect
                        x={553.769}
                        y={92.438}
                        fill="#FFFFFF"
                        width={23.639}
                        height={5.426}
                    />
                    <rect
                        x={594.438}
                        y={92.438}
                        fill="#FFFFFF"
                        width={23.639}
                        height={5.426}
                    />
                    <rect
                        x={635.108}
                        y={92.438}
                        fill="#FFFFFF"
                        width={23.637}
                        height={5.426}
                    />
                    <g>
                        <path
                            fill="#FFFFFF"
                            d="M460.441,130c-4.568,8.154-8.709,16.571-12.382,25.254c-5.226,12.35-9.431,25.004-12.61,37.903h11.932
			c5.846-22.479,14.849-43.694,26.489-63.157H460.441L460.441,130z"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M781.229,19.979C749.885,6.723,716.594,0,682.281,0c-34.313,0-67.604,6.723-98.948,19.979
			c-30.27,12.807-57.453,31.135-80.798,54.479c-7.472,7.471-14.424,15.342-20.854,23.576h14.999
			c44.553-52.863,111.219-86.508,185.6-86.508c55.646,0,106.977,18.826,147.959,50.443l10.602-6.465
			C822.707,40.996,802.752,29.084,781.229,19.979z"
                        />
                    </g>
                    <rect
                        x={915.973}
                        y={339.729}
                        fill="#FFFFFF"
                        width={446.777}
                        height={10.843}
                    />
                    <text
                        transform="matrix(1 0 0 1 471.9712 282.5723)"
                        fill="#FFFFFF"
                        fontFamily="'Abel-Regular'"
                        fontSize={74.5598}
                    >
                        {'STARTER PACK'}
                    </text>
                    <rect
                        x={688.242}
                        y={326.289}
                        fill="none"
                        width={152.598}
                        height={40.999}
                    />
                    <rect
                        x={311.757}
                        y={92.438}
                        fill="#FFFFFF"
                        width={23.637}
                        height={5.426}
                    />
                    <rect
                        x={915.973}
                        y={339.727}
                        fill="#FFFFFF"
                        width={450.027}
                        height={10.844}
                    />
                    <rect
                        x={688.243}
                        y={326.289}
                        fill="none"
                        width={152.598}
                        height={41}
                    />
                    <rect
                        x={848.72}
                        y={372.998}
                        fill="#FFFFFF"
                        width={517.28}
                        height={7.455}
                    />
                </g>
                <g id="o-projekcie-strzalka">
                    <text
                        transform="matrix(1 0 0 1 688.2129 354.8457)"
                        fill="#FFFFFF"
                        fontFamily="'Abel-Regular'"
                        fontSize={34}
                    >
                        {'O projekcie'}
                    </text>
                    <g>
                        <g enableBackground="new    ">
                            <path
                                fill="#FFFFFF"
                                d="M665.217,335.389l7.39,7.391l-1.514,1.514l-7.39-7.391L665.217,335.389z"
                            />
                        </g>
                        <g enableBackground="new    ">
                            <path
                                fill="#FFFFFF"
                                d="M648.8,342.791h23.807v2.141H648.8V342.791z"
                            />
                        </g>
                        <g enableBackground="new    ">
                            <path
                                fill="#FFFFFF"
                                d="M663.703,350.818l7.39-7.389l1.514,1.514l-7.39,7.389L663.703,350.818z"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}

export default SvgSvgStarterPackLg;
