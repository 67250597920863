import React from 'react';

class SvgSvgContactXs extends React.Component {
    render() {
        const { ...props } = this.props;

        return (
            <svg viewBox="0 0 360 640"  {...props}>
                <path
                    className="st3"
                    d="M354.563 494.997L4.814 553.208v22.084l349.749 58.211z"
                    id="mail"
                />
                <g id="phone">
                    <path
                        className="st3"
                        d="M4.814 552.728l349.749-58.211v-22.084L4.814 414.222z"

                    />
                    <path className="st3" d="M354.5 324.508L5 395.842v30.333l349.5 40z" />
                </g>
                <g id="map">
                    <path
                        className="st3"
                        d="M354.563 332.747L4.814 390.958v22.084l349.749 58.211z"
                    />
                    <path
                        className="st3"
                        d="M354.563 332.747L4.814 390.958v22.084l349.749 58.211z"
                    />
                </g>
                <g id="kontakt">
                    <path
                        className="st0"
                        d="M360.063 246.553l-20.917-20.916-61.213 61.213 54.427 54.427 6.786-2.199 11.379-2.396 9.538-9.536z"
                    />
                    <path
                        className="st4"
                        d="M300.146 347.361l-35.02 5.707 39.58-39.579 26.925 26.924z"
                    />
                    <path className="st2" d="M360 637L-.003 577.167V640H360z" />
                    <path
                        className="st0"
                        d="M166.615 364.5l-55.421 4.409 55.421-55.42 40.087 40.085zM0 396.5l-.003-138.788 111.197 111.201z"
                    />
                    <path
                        transform="rotate(45.001 111.2 299.815)"
                        className="st4"
                        d="M62.344 250.971h97.702v97.704H62.344z"
                    />
                    <path
                        className="st3"
                        d="M105.833 351.857l7.818-7.746.958.977-7.817 7.746zM121.286 346.684l-10.323-10.323.928-.93 10.324 10.324-.929.929zm5.04-5.04l-9.05-1.274-1.274-9.049 1.151-1.152 1.195 9.129 9.129 1.195-1.151 1.151zM133.01 332.151a3.596 3.596 0 0 1 0 2.701 3.52 3.52 0 0 1-.789 1.185l-.734.733a3.59 3.59 0 0 1-3.892.796 3.508 3.508 0 0 1-1.185-.788l-5.543-5.544a3.497 3.497 0 0 1-.789-1.184 3.586 3.586 0 0 1 .795-3.892l.735-.734a3.483 3.483 0 0 1 1.185-.788 3.594 3.594 0 0 1 2.7 0c.44.176.834.438 1.185.788l5.543 5.544c.352.349.613.745.789 1.183zm-7.26-5.798a2.281 2.281 0 0 0-.774-.515 2.354 2.354 0 0 0-1.749 0 2.224 2.224 0 0 0-.774.515l-.649.647c-.23.23-.402.489-.515.774a2.41 2.41 0 0 0-.173.879 2.273 2.273 0 0 0 .68 1.651l5.544 5.543a2.282 2.282 0 0 0 1.656.685c.302 0 .596-.056.882-.168.285-.113.543-.285.774-.517l.647-.647a2.278 2.278 0 0 0 .681-1.651 2.452 2.452 0 0 0-.173-.879 2.276 2.276 0 0 0-.515-.774l-5.542-5.543zM142.149 325.82l-11.82-3.212-.533-.359 7.963 7.962-.929.929-10.324-10.323.482-.482 11.829 3.218.532.346-7.963-7.962.929-.93 10.324 10.324-.49.489zM138.068 311.025l9.438 9.438-.929.929-9.438-9.438-2.463 2.463-.886-.886 5.854-5.853.886.885-2.462 2.462zM152.474 310.688l-3.27 3.268 1.729 3.081-.965.965-7.301-13.348.49-.489 13.361 7.286-.957.957-3.087-1.72zm-3.903 2.13l2.772-2.772-6.119-3.398-.41-.353.353.41 3.404 6.113zM157.844 310.125l-10.324-10.324.929-.928 10.324 10.323-.929.929zm5.04-5.04l-9.05-1.273-1.274-9.05 1.152-1.151 1.194 9.128 9.129 1.195-1.151 1.151zM158.579 290.515l9.438 9.438-.929.928-9.438-9.438-2.462 2.463-.886-.886 5.854-5.854.885.886-2.462 2.463z"
                    />
                    <path
                        className="st2"
                        d="M360 639.994L-.749 579.952v-31.404L360 488.506v151.488zM10.251 570.632L349 627.012V501.488l-338.749 56.38v12.764z"
                    />
                    <path
                        className="st2"
                        d="M-.686 559.219V407.73l360.748 60.042v31.404L-.686 559.219zm11-138.506v125.523l338.748-56.38v-12.764L10.314 420.713z"
                    />
                    <path
                        className="st4"
                        d="M239.719 355l-27.796 3.799-45.312-45.308 69.088-69.088 69.086 69.088-36.287 36.283z"
                    />
                    <path
                        className="st2"
                        d="M360.063 477.744L-.686 417.702v-31.404l360.749-60.042v151.488zM10.314 408.382l338.749 56.38V339.238l-338.749 56.38v12.764z"
                    />
                </g>
            </svg>
        );
    }
}

export default SvgSvgContactXs;
