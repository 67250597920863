import React from 'react';
import PropTypes from 'prop-types';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Contact from '../components/Contact';
import { withWidth } from '../components/withWidth';
import { withWPData } from '../components/withWPData';
import './index.scss';
import { Location } from '@reach/router';


class Layout extends React.Component {

    static propTypes = {
        width: PropTypes.number.isRequired,
        children: PropTypes.node.isRequired,
        isLoading: PropTypes.bool.isRequired,
    };

    render() {
        const { children, width, wpData, isLoading } = this.props;
        return (
            <React.Fragment>
                <Location>
                    {({ location }) => (
                        <Header
                            width={width}
                            projectDescription={wpData ? wpData.content.rendered : ''}
                            isLoading={isLoading}
                            location={location ? location.pathname : ''}
                        />
                    )}
                </Location>
                {children}
                <Contact
                    width={width}
                />
                <Footer />
            </React.Fragment>
        );
    }
}

export default withWPData(105)(withWidth(Layout));
