import constants from '../constants/javascriptConstants';


export function getSizeFromWidth(width) {
    // console.log(width);
    if (width <= 0 ) return 'none';
    else if (width < constants.mdMinBreakpoint ) return 'xs';
    // else if (width < 600 ) return 'sm';
    else if (width < constants.lgMinBreakpoint ) return 'md';
    else if (width < constants.xlMinBreakpoint ) return 'lg';
    else return 'xl';
}
