import React from 'react';
import PropTypes from 'prop-types';

/**
 * Żeby poprawnie działało ustawianie koloru należy ustawić atrybut `fill` tak by w odpowiednich miejscacach przyjmował wartość zmiennej `color`. to jest: `fill={color}`.
 * Jeżeli któryś z atrybutów 'fill' ma pozostać z zafiksowanym kolorem to należy wartość hexową podmienić na zmienną css, czyli fill="#FFF" powinien stać się fill="var(--white)".
 *
 * Na koniec należy zmienić nazwę pliku tak by zaczynała się od 'Svg'. np. SvgPiorunek.js
 *
 *  Jeśli ikona ma mieć rozmiar w przyjmowanych props'ach to:
 *  # należy w propTypes i defaultProps odkomentować linijkę z parametrem size i w defaultProps ustawić jakąś wartość.
 *  # znaleść tag 'svg' i fragment 'width="1em" height="1em"' podmienić na 'width={`${size}px`} height={`${size}px`}'
 *  # odkomentować 'size'  w  'const {//size, color, ...props} = this.props;'
 */

class SvgSvgIconEmail extends React.Component {
    static propTypes = {
        height: PropTypes.number
    };

    static defaultProps = {
        height: 100
    };

    render() {
        const { height, ...props } = this.props;

        return (
            <svg viewBox="0 0 136.168 102.127" width={(136.168 / 102.127) * height} height={height} {...props}>
                <path
                    d="M123.401 0H12.765C5.718 0 0 5.715 0 12.771v76.59c0 7.054 5.718 12.766 12.765 12.766h110.637c7.05 0 12.767-5.712 12.767-12.766v-76.59C136.168 5.715 130.451 0 123.401 0zm0 12.771v10.851c-5.963 4.856-15.468 12.407-35.791 28.319-4.479 3.521-13.354 11.986-19.521 11.888-6.173.102-15.05-8.365-19.527-11.888-20.314-15.911-29.827-23.464-35.793-28.32v-10.85h110.632zM12.765 89.36V39.998c6.098 4.857 14.741 11.667 27.91 21.981 5.813 4.575 15.995 14.675 27.411 14.612 11.357.063 21.412-9.891 27.403-14.61 13.173-10.313 21.814-17.123 27.909-21.983V89.36H12.765z"
                />
            </svg>
        );
    }
}

export default SvgSvgIconEmail;
