import React from 'react';
import PropTypes from 'prop-types';

/**
 * Żeby poprawnie działało ustawianie koloru należy ustawić atrybut `fill` tak by w odpowiednich miejscacach przyjmował wartość zmiennej `color`. to jest: `fill={color}`.
 * Jeżeli któryś z atrybutów 'fill' ma pozostać z zafiksowanym kolorem to należy wartość hexową podmienić na zmienną css, czyli fill="#FFF" powinien stać się fill="var(--white)".
 *
 * Na koniec należy zmienić nazwę pliku tak by zaczynała się od 'Svg'. np. SvgPiorunek.js
 *
 *  Jeśli ikona ma mieć rozmiar w przyjmowanych props'ach to:
 *  # należy w propTypes i defaultProps odkomentować linijkę z parametrem size i w defaultProps ustawić jakąś wartość.
 *  # znaleść tag 'svg' i fragment 'width="1em" height="1em"' podmienić na 'width={`${size}px`} height={`${size}px`}'
 *  # odkomentować 'size'  w  'const {//size, color, ...props} = this.props;'
 */

class SvgSvgIconPhone extends React.Component {
    static propTypes = {
        height: PropTypes.number
    };

    static defaultProps = {
        height: 100
    };

    render() {
        const { height, ...props } = this.props;

        return (
            <svg viewBox="0 0 123.611 123.611" width={(123.611 / 123.611) * height} height={height} {...props}>
                <path
                    d="M119.12 5.946L94.013.149c-2.725-.632-5.525.795-6.639 3.354l-11.59 27.041c-1.012 2.366-.336 5.146 1.668 6.762l14.635 11.976C83.392 67.8 68.204 83.203 49.302 92.058L37.33 77.43a5.79 5.79 0 0 0-6.762-1.667L3.525 87.347C.945 88.49-.481 91.286.148 94.016l5.793 25.104a5.793 5.793 0 0 0 5.648 4.49c61.832 0 112.021-50.095 112.021-112.021a5.773 5.773 0 0 0-4.49-5.643z"
                />
            </svg>
        );
    }
}

export default SvgSvgIconPhone;
