import React from 'react';

class SvgSvgStarterPackXl extends React.Component {
    render() {
        const { ...props } = this.props;

        return (
            <svg
                version={1.1}
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 1920 510"
                enableBackground="new 0 0 1920 510"
                xmlSpace="preserve"
                {...props}
            >
                <g id="Layer_1">
                    <g id="StarterPack" />
                    <rect y={183.22} fill="#FFFFFF" width={720.505} height={10.846} />
                    <rect
                        x={1208.47}
                        y={183.56}
                        fill="#FFFFFF"
                        width={711.53}
                        height={10.845}
                    />
                    <rect y={340.636} fill="#FFFFFF" width={720.505} height={10.844} />
                    <rect
                        x={628.174}
                        y={93.345}
                        fill="#FFFFFF"
                        width={23.638}
                        height={5.424}
                    />
                    <rect
                        x={668.843}
                        y={93.345}
                        fill="#FFFFFF"
                        width={23.639}
                        height={5.424}
                    />
                    <rect
                        x={709.511}
                        y={93.345}
                        fill="#FFFFFF"
                        width={23.64}
                        height={5.424}
                    />
                    <rect
                        x={746.722}
                        y={93.515}
                        fill="#FFFFFF"
                        width={23.638}
                        height={5.424}
                    />
                    <rect
                        x={790.851}
                        y={93.345}
                        fill="#FFFFFF"
                        width={23.639}
                        height={5.424}
                    />
                    <rect
                        x={831.521}
                        y={93.345}
                        fill="#FFFFFF"
                        width={23.638}
                        height={5.424}
                    />
                    <rect
                        x={872.189}
                        y={93.345}
                        fill="#FFFFFF"
                        width={23.637}
                        height={5.424}
                    />
                    <rect
                        x={912.858}
                        y={93.345}
                        fill="#FFFFFF"
                        width={23.636}
                        height={5.424}
                    />
                    <g>
                        <path
                            fill="#FFFFFF"
                            d="M738.192,130.908c-4.569,8.153-8.709,16.571-12.381,25.254c-5.227,12.35-9.432,25.004-12.612,37.903
			h11.932c5.845-22.479,14.849-43.694,26.489-63.157H738.192L738.192,130.908z"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M1058.979,20.886C1027.635,7.63,994.344,0.907,960.031,0.907c-34.313,0-67.605,6.723-98.948,19.979
			c-30.271,12.805-57.454,31.133-80.798,54.478c-7.472,7.471-14.424,15.342-20.854,23.575h14.999
			c44.553-52.862,111.219-86.508,185.6-86.508c55.646,0,106.978,18.828,147.96,50.445l10.602-6.465
			C1100.458,41.904,1080.503,29.992,1058.979,20.886z"
                        />
                    </g>
                    <rect
                        x={1193.723}
                        y={340.636}
                        fill="#FFFFFF"
                        width={726.277}
                        height={10.844}
                    />
                    <text
                        transform="matrix(1 0 0 1 749.7217 283.4795)"
                        fill="#FFFFFF"
                        fontFamily="'Abel-Regular'"
                        fontSize={74.5598}
                    >
                        {'STARTER PACK'}
                    </text>
                    <rect
                        x={965.993}
                        y={327.198}
                        fill="none"
                        width={152.598}
                        height={41}
                    />
                    <rect
                        x={589.508}
                        y={93.345}
                        fill="#FFFFFF"
                        width={23.638}
                        height={5.424}
                    />
                    <path
                        fill="#FFFFFF"
                        d="M1167.21,381.145c-42.672,69.766-119.578,116.424-207.18,116.424c-63.074,0-120.602-24.193-163.799-63.783
		h-16.78c0.28,0.281,0.552,0.572,0.834,0.855c23.343,23.342,50.526,41.67,80.797,54.475c31.343,13.256,64.634,19.977,98.947,19.977
		s67.606-6.721,98.948-19.977c30.271-12.805,57.453-31.133,80.799-54.475c16.091-16.092,29.796-34.004,40.953-53.494h-13.521
		L1167.21,381.145L1167.21,381.145z"
                    />
                    <rect
                        x={1126.5}
                        y={373.689}
                        fill="#FFFFFF"
                        width={532.896}
                        height={7.455}
                    />
                </g>
                <g id="o-projekcie-strzalka">
                    <text
                        transform="matrix(1 0 0 1 965.9932 355.5371)"
                        fill="#FFFFFF"
                        fontFamily="'Abel-Regular'"
                        fontSize={34}
                    >
                        {'O projekcie'}
                    </text>
                    <g>
                        <g enableBackground="new    ">
                            <path
                                fill="#FFFFFF"
                                d="M942.967,336.299l7.39,7.391l-1.514,1.514l-7.39-7.391L942.967,336.299z"
                            />
                        </g>
                        <g enableBackground="new    ">
                            <path
                                fill="#FFFFFF"
                                d="M926.55,343.701h23.807v2.141H926.55V343.701z"
                            />
                        </g>
                        <g enableBackground="new    ">
                            <path
                                fill="#FFFFFF"
                                d="M941.453,351.729l7.39-7.389l1.514,1.514l-7.39,7.389L941.453,351.729z"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}

export default SvgSvgStarterPackXl;
