import React from 'react';

class SvgSvgContactMd extends React.Component {
    render() {
        const { ...props } = this.props;

        return (
            <svg viewBox="0 0 768.043 568.145"  {...props}>
                <path
                    className="st2"
                    d="M10.521 205.083l352 346.99-352 4z"
                    id="email"
                />
                <path
                    className="st2"
                    d="M576.633 378.597L384.021 189.685 191.41 378.597l192.611 188.911z"
                    id="phone"
                />
                <path className="st2" d="M756.121 205.083v354h-354z" id="map" />
                <g id="shapes-contact">
                    <path
                        className="st5"
                        d="M313.092 246.674L191.409 127.33 69.729 246.674l121.68 119.344zM744.793 202.67L576.632 37.739 408.472 202.67l168.16 164.931z"
                    />
                    <path
                        transform="scale(-1) rotate(-44.433 -717.62 1377.766)"
                        className="st2"
                        d="M541.168 291.713h43.139v2.804h-43.139z"
                    />
                    <path
                        className="st0"
                        d="M384.178 177.235l-71.026-69.662-71.147 69.774 71.086 69.659z"
                    />
                    <path
                        transform="rotate(134.999 653.103 212.388)"
                        fill="none"
                        d="M578.79 188.555h148.619v47.662H578.79z"
                    />
                    <path
                        className="st2"
                        d="M596.963 278.097l-21.174-21.174 1.904-1.904 21.173 21.174-1.903 1.904zm10.335-10.336l-18.562-2.613-2.613-18.561 2.363-2.361 2.45 18.721 18.723 2.451-2.361 2.363zM621.008 248.292c.358.9.539 1.824.539 2.77s-.18 1.869-.538 2.77a7.147 7.147 0 0 1-1.617 2.428l-1.506 1.506a7.259 7.259 0 0 1-2.438 1.625 7.399 7.399 0 0 1-2.776.545 7.388 7.388 0 0 1-2.769-.539 7.131 7.131 0 0 1-2.429-1.617l-11.369-11.369a7.145 7.145 0 0 1-1.617-2.43 7.4 7.4 0 0 1-.539-2.768c0-.946.182-1.871.547-2.777A7.223 7.223 0 0 1 596.12 236l1.506-1.506a7.17 7.17 0 0 1 2.43-1.617 7.405 7.405 0 0 1 2.77-.539c.943 0 1.867.18 2.769.539a7.184 7.184 0 0 1 2.43 1.617l11.369 11.369a7.23 7.23 0 0 1 1.614 2.429zM606.117 236.4a4.652 4.652 0 0 0-1.588-1.057 4.869 4.869 0 0 0-1.794-.346 4.86 4.86 0 0 0-1.794.346 4.633 4.633 0 0 0-1.587 1.057l-1.329 1.328a4.653 4.653 0 0 0-1.056 1.588 4.969 4.969 0 0 0-.354 1.801 4.792 4.792 0 0 0 .339 1.803 4.637 4.637 0 0 0 1.058 1.586l11.369 11.371c.473.471 1.002.824 1.588 1.055a4.89 4.89 0 0 0 3.619 0 4.631 4.631 0 0 0 1.588-1.055l1.328-1.33a4.682 4.682 0 0 0 1.057-1.586 4.832 4.832 0 0 0 .34-1.803c-.006-.615-.124-1.215-.354-1.801s-.583-1.117-1.056-1.588L606.117 236.4zM639.754 235.306l-24.245-6.584-1.094-.738 16.33 16.33-1.903 1.904-21.174-21.173.989-.989 24.261 6.601 1.092.708-16.33-16.33 1.903-1.905 21.175 21.173-1.004 1.003zM631.381 204.963l19.356 19.357-1.905 1.905-19.356-19.357-5.05 5.05-1.815-1.815 12.004-12.006 1.816 1.816-5.05 5.05zM660.927 204.269l-6.703 6.705 3.543 6.319-1.979 1.979-14.972-27.375 1.004-1.004 27.404 14.941-1.964 1.965-6.333-3.53zm-8.003 4.371l5.685-5.685-12.551-6.969-.842-.724.725.842 6.983 12.536zM671.942 203.118l-21.174-21.173 1.904-1.904 21.174 21.174-1.904 1.903zm10.335-10.336l-18.56-2.614-2.613-18.561 2.361-2.361 2.45 18.723 18.724 2.45-2.362 2.363zM673.448 162.896l19.357 19.357-1.905 1.905-19.357-19.356-5.05 5.049-1.815-1.815 12.005-12.004 1.814 1.815-5.049 5.049z"
                    />
                    <path
                        className="st0"
                        d="M70.16 55.96L0 124.823v52.261l70.16 69.59 97.221-95.359z"
                    />
                    <path
                        className="st4"
                        d="M396.954 568.645L0 177.084v391.561h396.954zM18.474 220.918L352.762 550.66H18.474V220.918z"
                    />
                    <path
                        className="st4"
                        d="M768.043 568.645V177.084L371.089 568.645h396.954zm-18.475-17.984H415.281l334.287-329.742v329.742z"
                    />
                    <path
                        className="st4"
                        d="M396.954 568.645L0 177.084v391.561h396.954zM18.474 220.918L352.762 550.66H18.474V220.918z"
                    />
                    <path
                        className="st4"
                        d="M395.716 568.645l193.771-190.048-205.466-201.518-205.464 201.518 193.77 190.048h23.389zm-11.695-366.353l179.758 176.306-179.758 176.304-179.758-176.305 179.758-176.305z"
                    />
                </g>
            </svg>
        );
    }
}

export default SvgSvgContactMd;
