import React, { Component } from 'react';
import './SkewedDiv.scss';
import PropTypes from 'prop-types';


export default class SkewedDiv extends Component {
    static propTypes  = {
        className: PropTypes.string,
        innerStyle: PropTypes.object,
        beforeChildren: PropTypes.node,
        afterChildren: PropTypes.node,
    };

    static defaultProps = {
        className: '',
        innerStyle: {},
        beforeChildren: null,
        afterChildren: null,
    };

    render() {
        const {
            children,
            className,
            beforeChildren,
            afterChildren,
            innerStyle,
            ...otherProps
        } = this.props;
        return (
            <div className={className + ' skewed-div'} {...otherProps}>
                {beforeChildren}
                <div style={innerStyle} className="skewed-div-inner">
                    {children}
                </div>
                {afterChildren}
            </div>
        );
    }
}
