import React from 'react';
import PropTypes from 'prop-types';

/**
 * Żeby poprawnie działało ustawianie koloru należy ustawić atrybut `fill` tak by w odpowiednich miejscacach przyjmował wartość zmiennej `color`. to jest: `fill={color}`.
 * Jeżeli któryś z atrybutów 'fill' ma pozostać z zafiksowanym kolorem to należy wartość hexową podmienić na zmienną css, czyli fill="#FFF" powinien stać się fill="var(--white)".
 *
 * Na koniec należy zmienić nazwę pliku tak by zaczynała się od 'Svg'. np. SvgPiorunek.js
 *
 *  Jeśli ikona ma mieć rozmiar w przyjmowanych props'ach to:
 *  # należy w propTypes i defaultProps odkomentować linijkę z parametrem size i w defaultProps ustawić jakąś wartość.
 *  # znaleść tag 'svg' i fragment 'width="1em" height="1em"' podmienić na 'width={`${size}px`} height={`${size}px`}'
 *  # odkomentować 'size'  w  'const {//size, color, ...props} = this.props;'
 */

class SvgSvgIconMap extends React.Component {
    static propTypes = {
        height: PropTypes.number
    };

    static defaultProps = {
        height: 100
    };

    render() {
        const { height, ...props } = this.props;

        return (
            <svg viewBox="0 0 153.835 119.184" width={(153.835 / 119.184) * height} height={height} {...props}>
                <path
                    d="M147.333 2.5c-.51 0-1.034.092-1.551.3l-44.295 16.369h-.003l-44.8-15.815a16.65 16.65 0 0 0-5.271-.854c-1.744 0-3.483.271-5.16.813L6.707 17.068a8.341 8.341 0 0 0-5.24 7.737v90.204c0 2.396 1.962 4.174 4.166 4.174.509 0 1.035-.098 1.552-.301l44.293-16.37 44.798 15.813a16.684 16.684 0 0 0 10.436.039l39.545-13.756a8.333 8.333 0 0 0 5.241-7.739V6.668c0-2.391-1.963-4.168-4.165-4.168zm-87.52 15.215L93.15 29.483v74.484l-33.338-11.77V17.715zm-45.842 85.336v-75.27L47.313 16.18v74.545l-.167.063-33.175 12.263zm125.022-9.153l-33.339 11.603V30.958l.165-.063 33.174-12.26v75.263z"
                />
            </svg>
        );
    }
}

export default SvgSvgIconMap;
