import React from 'react';
import PropTypes from 'prop-types';

/**
 * Żeby poprawnie działało ustawianie koloru należy ustawić atrybut `fill` tak by w odpowiednich miejscacach przyjmował wartość zmiennej `color`. to jest: `fill={color}`.
 * Jeżeli któryś z atrybutów 'fill' ma pozostać z zafiksowanym kolorem to należy wartość hexową podmienić na zmienną css, czyli fill="#FFF" powinien stać się fill="var(--white)".
 *
 * Na koniec należy zmienić nazwę pliku tak by zaczynała się od 'Svg'. np. SvgPiorunek.js
 *
 *  Jeśli ikona ma mieć rozmiar w przyjmowanych props'ach to:
 *  # należy w propTypes i defaultProps odkomentować linijkę z parametrem size i w defaultProps ustawić jakąś wartość.
 *  # znaleść tag 'svg' i fragment 'width="1em" height="1em"' podmienić na 'width={`${size}px`} height={`${size}px`}'
 *  # odkomentować 'size'  w  'const {//size, color, ...props} = this.props;'
 */

class SvgZnakLudziWezy extends React.Component {
    static propTypes = {
        height: PropTypes.number
    };

    static defaultProps = {
        height: 100
    };

    render() {
        const { height, ...props } = this.props;

        return (
            <svg
                viewBox="202.573 94.526 190.134 220.003"
                width={(190.134 / 220.003) * height}
                height={height}
                {...props}
            >
                <path
                    d="M202.573 153.53c1.182-5.295 2.062-10.679 3.598-15.868 6.922-23.399 24.383-35.341 46.447-42.038 2.638-.801 5.534-1.044 8.312-1.049 39.829-.065 79.657-.046 119.487-.047h6.455l-6.236 43.284c-22.195 0-43.994-.223-65.781.077-14.748.203-29.539.588-44.213 1.945-9.687.896-14.178 7.604-14.067 17.855.102 9.412 4.355 14.487 14.345 15.815 16.909 2.248 33.882 4.003 50.82 6.06 13.598 1.65 27.293 2.73 39.955 8.856 17.051 8.25 26.703 21.93 29.717 40.287 2.086 12.707 1.646 25.491-1.271 38.093-5.045 21.771-19.625 34.213-40.186 40.623-13.824 4.31-27.986 6.508-42.5 6.563-21.311.082-42.62.375-63.93.503-13.109.077-26.219.015-40.405.015 1.443-8.158 2.562-14.73 3.777-21.286 1.173-6.331 2.708-12.608 3.575-18.978.512-3.767 1.958-4.828 5.719-4.805 26.663.167 53.328.237 79.99.003 8.273-.071 16.6-.749 24.791-1.919 12.119-1.73 17.135-7.247 17.67-17.076.779-14.292-7.965-21.237-17.504-22.827-19.73-3.289-39.493-6.472-59.327-9.037-23.74-3.07-42.699-13.082-53.418-35.32-2.808-5.825-3.925-12.466-5.818-18.731-.002-3.665-.002-7.33-.002-10.998z"
                />
            </svg>
        );
    }
}


export default SvgZnakLudziWezy;
